.input_slip input:focus {
  background-color: #fff !important;
}
.custom_selector {
  width: 80px;
  right: 5px;
  outline: none !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}
.custom_selector:focus {
  background-color: #fff !important;
}
td input:focus {
  background-color: #fff !important;
}

.border {
  border: 1px solid !important;
}

.mw-fit {
  min-width: fit-content;
}
